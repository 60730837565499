<template>
  <div class="all_main_wrap">
    <div class="all_header_box">
      <div class="sider_left">
        <span class="title">
          <router-link
            class="crumbs_item"
            tag="a"
            to=""
            @click.native="$router.go(-1)"
            >栏目管理 /
          </router-link>
          <span class="crumbs_item crumbs_last" v-if="!id">{{
            "新建"
          }}</span>
          <span class="crumbs_item crumbs_last" v-else>{{
            "编辑"
          }}</span>
        </span>
      </div>
    </div>
    <div class="all_content_box">
      <div class="L_R_inner">
        <span class="all_left_name">栏目名称：</span>
        <div class="right_Div">
          <a-input
            style="pointer-events:none;"
            v-model="ftitle"
            autocomplete="off"
            class="all_input"
          />
        </div>
      </div>
      <div class="L_R_inner" v-if="fremark != 0">
        <span class="all_left_name">栏目备注：</span>
        <div class="right_Div">
          <a-textarea
            style="pointer-events:none;"
            v-model="fremark"
            :auto-size="{ minRows: 3 }"
            autocomplete="off"
            class="all_input"
          />
        </div>
      </div>
      <div class="L_R_inner">
        <span class="all_left_name">子栏目名称：</span>
        <div class="right_Div">
          <a-input
            v-model="title"
            autocomplete="off"
            class="all_input"
          />
        </div>
      </div>
      <div class="L_R_inner">
        <span class="all_left_name">图片：</span>
        <div class="right_Div required">
          <span class="ant-upload-picture-card-wrapper" v-if="banner">
            <div
              class="
                ant-upload
                ant-upload-select
                ant-upload-select-picture-card
              "
            >
              <span role="button" tabindex="0" class="ant-upload">
                <div class="pictures">
                  <img
                    style="max-height: 100px; max-width: 100px"
                    :src="banner"
                    alt="avatar"
                  />
                  <div class="icon">
                    <a
                      :href="banner"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <a-icon type="eye" class="i" />
                    </a>
                    <a-icon
                      @click="banner = ''"
                      type="delete"
                      class="i"
                    />
                  </div>
                </div>
              </span>
            </div>
          </span>
          <a-upload
            v-else
            name="app"
            list-type="picture-card"
            :showUploadList="false"
            accept=".jpg,.png,.jpeg,.JPG,.PNG,.JPEG" 
            :beforeUpload="beforeUpload"
            :customRequest="customRequest"
          >
            <div>
              <a-icon :type="loading ? 'loading' : 'plus'" />
              <div class="ant-upload-text">上传图片</div>
            </div>
          </a-upload>
        </div>
      </div>
      <div class="L_R_inner">
        <span class="all_left_name ">介绍：</span>
        <div class="right_Div">
          <a-textarea
            v-model="detail"
            autocomplete="off"
            :auto-size="{ minRows: 3 }"
            class="all_input"
            placeholder="请输入介绍"
          />
        </div>
      </div>
      <div class="L_R_inner">
        <span class="all_left_name ">是否可分享：</span>
        <div class="right_Div">
          <a-radio-group v-model="isShare" name="radioGroup">
            <a-radio :value="1">是</a-radio>
            <a-radio :value="0">否</a-radio>
          </a-radio-group>
        </div>
      </div>
      <div class="L_R_inner">
        <span class="all_left_name ">推荐商品：</span>
        <div class="right_Div">
          <a-button type="primary" class="btn" @click="visible = true"
            >添加</a-button
          >
        </div>
      </div>
      <a-table
        class="table-template"
        style="margin-left: 155px; width: 900px"
        :columns="columns"
        :rowKey="(item,index) => index"
        :data-source="productList"
        @change="onPage"
        :pagination="{
          total: total,
          showTotal: (res) => `共${total}条`,
          showQuickJumper: true,
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '30', '50', '100'],
        }"
      >
        <template slot="index" slot-scope="item, row, i">
          <div style="text-align: center">
            {{(pageNum - 1) * pageSize + i + 1}}
          </div>
        </template>
        <template slot="sort" slot-scope="item, row, i">
          <div style="display:flex;align-items:center;justify-content: center">
            <a-input-number v-if="item.isEdit == 1" id="inputNumber" v-model="productList[(pageNum - 1) * pageSize + i].sort" /> 
            <span v-else>{{item.sort}}</span> 
            <a-icon style="margin-left:10px" :type="item.isEdit == 1 ? 'save' : 'edit'" @click="onEditSort((pageNum - 1) * pageSize + i)" />
          </div>
        </template>
        <template slot="image" slot-scope="item">
          <div style="text-align: center">
            <viewer v-if="item">
              <img v-if="item" style="max-width:100px" :src="item" alt="">
            </viewer>
            <span v-else>-</span>
          </div>
        </template>
        <template slot="productType" slot-scope="item">
          <span>{{funProductType(item)}}</span>
        </template>
        
        <template slot="catalogue" slot-scope="item,row, i">
          <a style="color:#3681F0" @click="removeData(row, (pageNum - 1) * pageSize + i)">删除</a>
          |
          <a style="color:#3681F0" @click="onEditOpen(row)">编辑</a>
        </template>
      </a-table>
      <div class="button" style="margin-left:40px;margin-top:40px">
        <a-button type="primary" class="btn" :loading="submitLoading" @click="onSubmit"
          >保存</a-button
        >
        <a-button class="all_boder_btn btn" @click="$router.go(-1)"
          >取消</a-button
        >
      </div>
    </div>
    
    <!-- 新建/编辑 -->
    <a-modal v-model="visible" title="添加商品" :confirm-loading="confirmLoading" @ok="setData()" okText="确认">
      <a-row class="rows">
        <a-col span="5" class="left require">商品名称</a-col>
        <a-col span="19" class="right flex" >
          <a-auto-complete
            style="width: 100%"
            v-model="productForm.name"
            placeholder="输入搜索关键字"
            option-label-prop="value"
            @search="onSearchRole"
            :defaultActiveFirstOption="false"
          >
            <a-spin
              v-if="fetching"
              class="auto-spin"
              slot="notFoundContent"
              size="small"
            />
            <template slot="dataSource">
              <a-select-option
                @click="onSelectRole(item)"
                v-for="(item, index) in list"
                :key="index + ''"
                :value="item.productName"
              >
                <a-row type="flex" justify="space-between" align="middle">
                  <a-col>{{ item.productName }}</a-col>
                </a-row>
              </a-select-option>
            </template>
          </a-auto-complete>
        </a-col>
      </a-row>
      <a-row class="rows">
        <a-col span="5" class="left">详细描述</a-col>
        <a-col span="19" class="right flex" >
          <a-input v-model="describe" placeholder="请填写详细描述"/>
        </a-col>
      </a-row>
      <a-row class="rows">
        <a-col span="5" class="left">概述</a-col>
        <a-col span="19" class="right flex" >
          <a-input v-model="overview" placeholder="若多条描述可用‘## ’分割"/>
        </a-col>
      </a-row>
      <a-row class="rows">
        <a-col span="5" class="left">图片</a-col>
        <a-col span="19" class="right" >
          <a-upload
            name="picture"
            accept=".jpg,.png,.jpeg,.JPG,.PNG,.JPEG"
            list-type="picture-card"
            :showUploadList="false"
            :loading="beforeUpload"
            :customRequest="customRequestProduct"
          >
            <img
              v-if="picture"
              :src="picture"
              style="max-width: 180px"
              alt="avatar"
            />
            <div v-else>
              <a-icon :type="loading ? 'loading' : 'plus'" />
              <div class="ant-upload-text">上传图片</div>
            </div>
          </a-upload>
        </a-col>
      </a-row>
    </a-modal>
  </div>
</template>

<script>
import UE from "@/components/UE.vue";
const columns = [
  {
    title: "序号",
    align: "center",
    width: "70px",
    scopedSlots: {
      customRender: "index",
    },
  },
  {
    title: "商品名称",
    align: "center",
    dataIndex: "productName",
  },
  {
    title: "排序",
    align: "center",
    scopedSlots: {
      customRender: "sort",
    },
  },
  {
    title: "商品类型",
    align: "center",
    dataIndex: "productType",
    scopedSlots: {
      customRender: "productType",
    },
  },
  {
    title: "概述",
    ellipsis: true,
    align: "center",
    dataIndex: "overview",
  },
  {
    title: "图片",
    align: "center",
    dataIndex: "picture",
    scopedSlots: {
      customRender: "image",
    },
  },
  {
    title: "操作",
    align: "center",
    scopedSlots: {
      customRender: "catalogue",
    },
  },
];
export default {
  // 可用组件的哈希表
  components: { UE }, // 接收传值
  props: {}, // 数据对象
  data() {
    return {
      columns,
      submitLoading:false,
      loading:false,
      id:'',
      form: this.$form.createForm(this, { name: "coordinated", }),
      total:0,
      productList:[],
      visible: false,
      confirmLoading: false,
      fetching: false,


      fremark:'', // 备注
      ftitle:'', // 栏目名称
      isShare:'', // 是否可分享
      title:'', // 栏目名称
      banner:'', // 封面
      detail:'', // 介绍

      // 商品参数
      productForm: {
        noGround: 1, // 商品上架状态1.上架 0下架
        name:'',
        page:1,
        size:10,
        noExam: 0 // 是否查询模拟考试商品 1查 0 不查
      },
      list:[],
      productId: 0,
      childProductId: undefined,
      picture: '',
      overview:'',
      describe:'',
      pageNum:1,
      pageSize: 10,

    };
  }, // 事件处理器
  methods: {
    funProductType(type){
      // 商品类型.1.课程 2.图书 3.教具 4.模拟考试 5.电子照片 6.延期 7.补考 8.直播 9其他
      const ProductMap = {
          1: '课程',
          2: '图书',
          3: '教具',
          4: '模拟考试',
          5: '电子照片',
          6: '延期',
          7: '补考',
          8: '直播',
          9: '9其他',
        };
      const chineseResult = ProductMap[type];
      return  chineseResult
    },
    // 打印选中商品
    onSelectRole(e) {
      this.productId = e.productId;
    },
    // 检索商品
    onSearchRole(val) {
      this.fetching = false;
      this.list = [];
      if (!val) {
        clearTimeout(this.tiemID);
        return;
      }
      this.$nextTick(function () {
        if (val !== "") {
          this.fetching = true;
          const _this = this;
          clearTimeout(this.tiemID);
          this.tiemID = setTimeout(function () {
            _this.productId = 0;
            _this
              .$ajax({
                method: "get",
                url: "/hxclass-management/product-archives/manage/select-win",
                params: _this.productForm,
              })
              .then((res) => {
                if (res.code == 200 && res.success) {
                  _this.list = res.data.records;
                  _this.fetching = false;
                }
              });
          }, 400);
        }
      });
    },
    // 查询数据详情
    getDetail(){
      this.$ajax({
        url: '/hxclass-management/ProductColumn/detail/' + this.id,
      }).then(res=>{
        if(res.code == 200 && res.success){
          let obj = res.data
          this.remark = obj.remark
          this.title = obj.title
          this.banner = obj.banner
          this.detail = obj.detail
          this.isShare = obj.isShare
        }
      }).catch(err=>{
        
      })
    },

    // 查询商品列表
    getList(){
      this.$ajax({
        url: '/hxclass-management/ProductColumnRelation/list',
        params: {
          columnId:  this.id
        }
      }).then(res=>{
        if(res.code == 200 && res.success){
          this.productList = res.data
          this.total = res.data.length
        }
      }).catch(err=>{
        
      })
    },

    // 修改排序
    onEditSort(index){
      let itemObj = this.productList[index]
      if(itemObj.isEdit){
        this.$ajax({
          url: '/hxclass-management/ProductColumnRelation/update',
          method: 'post',
          params: {
            id: itemObj.id,
            sort: itemObj.sort
          }
        }).then(res=>{
          this.$message.success("修改成功");
          this.$set(itemObj,'isEdit',0)
          this.getList()
        })
      } else {
        this.$set(itemObj,'isEdit',1)
      }
    },

    // 删除分类
    removeData(e,index){
      this.$confirm({
        title: '确定删除该商品吗?',
        okText: '确认',
        onOk:()=> {
          this.$ajax({
            url: '/hxclass-management/ProductColumnRelation/delete/' + e.id,
            method: 'delete'
          }).then(res=>{
            if(res.code == 200 && res.success){
              this.$message.success("删除成功");
              this.productList.splice(index, 1);
              this.total -- 
            }else{
              this.$message.error(res.message)
            }
          })
        }
      });
    },

    onEditOpen(e){
      this.productForm.name = e.productName
      this.productId = e.productId
      this.describe = e.detail
      this.overview = e.overview
      this.picture = e.picture
      this.childProductId = e.id
      this.visible = true
    },
    
    // 提交
    onSubmit() {
      if(!this.title){
        this.$message.error('请输入子栏目名称')
        return false
      }

      // 提交中
      if (!!this.submitLoading) {
        return
      }
  
      this.submitLoading = true //提交中
      this.$ajax({
        url: '/hxclass-management/ProductColumn/update',
        method: 'post',
        params: {
          id: this.id,
          title: this.title,
          banner: this.banner,
          detail: this.detail,
          isShare: this.isShare,
        }
      }).then(res=>{
        if(res.code == 200 && res.success){
          this.$message.success("成功");
          this.$router.go(-1);
        }else{
          this.$message.error(res.message)
        }
        this.submitLoading = false
      })
    },
    onPage(e){
      this.pageNum = e.current;
      this.pageSize = e.pageSize;
    },

    // 添加关联商品
    setData(){
      if(!this.productId){
        this.$message.error('请选择关联的商品')
        return false
      }

      this.$ajax({
        url: '/hxclass-management/ProductColumnRelation/update',
        method: 'post',
        params: {
          id: this.childProductId,
          columnId: this.id,
          overview: this.overview,
          detail: this.describe,
          picture: this.picture,
          productId: this.productId,
        }
      }).then(res=>{
        if(res.code == 200 && res.success){
          this.productId = undefined
          this.productForm.name = ''
          this.$message.success("成功");
          this.visible = false
          this.getList()
        }else{
          this.$message.error(res.message)
        }
        this.submitLoading = false
      })
    },
    // 上传前钩子
    beforeUpload(file) {
      if (!this.$regular.imgReg.reg.test(file.name)) {
        this.$message.warning(this.$regular.imgReg.msg);
        return false;
      }
    },

    // 文件上传
    customRequest(fileData) {
      this.loading = true;

      const bucket = 'ueditor/article/' // *必填 文件存储地址（地址参考cosUpload.js说明文件）
      // 腾讯云对象存储上传文件
      this.$cosUpload(fileData.file,bucket,this.fileProgressCallback,(url, fileName) => {
        // 上传完成
        if (url) {
          this.banner = url
        } else {
          this.$message.error("上传失败");
        }
        this.loading = false;
      });
    },

    // 文件上传
    customRequestProduct(fileData) {
      this.loading = true;

      const bucket = 'ueditor/article/' // *必填 文件存储地址（地址参考cosUpload.js说明文件）
      // 腾讯云对象存储上传文件
      this.$cosUpload(fileData.file,bucket,this.fileProgressCallback,(url, fileName) => {
        // 上传完成
        if (url) {
          this.picture = url
        } else {
          this.$message.error("上传失败");
        }
        this.loading = false;
      });
    },

    // 文件上传更新进度和单文件上传初始化
    fileProgressCallback(progress, speed, name) {
      /*
       * progress 进度
       * speed 传输速度
       * name 文件名称
       * */
    },
    
  }, // 生命周期-实例创建完成后调用
  created() {
   this.ftitle = this.$route.query.ftitle
   this.fremark = this.$route.query.fremark
   this.id = Number(this.$route.query.id)
    if(this.id){
      this.getDetail()
      this.getList()
    }
  }, // 生命周期-实例挂载后调用
  mounted() {}, // 生命周期-实例销毁离开后调用
  destroyed() {}, // 计算属性监听
  computed: {}, // 自定义的侦听器
  watch: {
    visible(){
      // 初始商品表单
      if(!this.visible){
        this.productForm.name = ''
        this.productId = undefined
        this.describe = ''
        this.overview = ''
        this.picture = ''
        this.childProductId = undefined
      }
    }
  },
};
</script>

<style lang="less" scoped>
/deep/.ant-checkbox-wrapper {
  margin-left: 0;
  margin-right: 40px;
}
.ant-upload-picture-card-wrapper {
  width: auto;
}
.all_main_wrap {
  a {
    color: #333333;
  }

  .all_content_box {
    .L_R_inner {

      .all_left_name {
        width: 140px;
        font-size: 14px;
        text-align: right;
      }
      .all_left_info {
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
      }

      .vue-ueditor-wrap[data-v-1e1388a0] {
        margin-left: 20px;
        width: 600px;
        margin-top: 10px;
      }

      .right_Div {
        .cascader-input,
        .ant-select,
        .all_input {
          width: 337px;
        }
        /deep/.ant-input {
          height: 37px;
          min-width: 500px;
        }
      }
      .editor{
        min-width: 1200px;
      }
    }
  }

  .button {
    .btn {
      margin-left: 20px;
    }
  }
}

.ant-upload.ant-upload-select-picture-card {
  display: table;
  float: left;
  width: 104px;
  height: 104px;
  margin-right: 8px;
  margin-bottom: 8px;
  text-align: center;
  vertical-align: top;
  background-color: #fafafa;
  border: 1px dashed #d9d9d9;
  border-radius: 4px;
  cursor: pointer;
  transition: border-color 0.3s ease;
}
.pictures .icon {
  transition: all 0.3s;
  opacity: 0;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.3);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.pictures:hover {
  .icon {
    opacity: 1;
  }
}
.pictures {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.ant-upload.ant-upload-select-picture-card > .ant-upload {
  display: table-cell;
  width: 100%;
  height: 100%;
  padding: 8px;
  text-align: center;
  vertical-align: middle;
}
.rows{
  margin-bottom: 20px;
  display: flex;
  &:last-child{
    margin-bottom: 0;
  }
  .left{
    text-align: right;
    height: 32px;
    line-height: 32px;
    min-width: 100px;
    &::after{
      content: '：';
    }
  }
  .require{
     &::before{
      content: '*';
      color: red;
      margin-right: 2px;
    }
  }
  .flex{
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 32px;
    input{
      flex: 1;
    }
    a{
      margin-left: 8px;
    }
  }
}
</style>
